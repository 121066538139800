function getUtcDate (procesesDate: Date) {
  return new Date(Date.UTC(procesesDate.getFullYear(),
    procesesDate.getMonth(),
    procesesDate.getDate(),
    procesesDate.getHours(),
    procesesDate.getMinutes()
  ));
};
function shareDateComparison (shareDate: string) {
  const hourDifference = (getUtcDate(new Date()).getTime() - getUtcDate(new Date(shareDate)).getTime()) / 1000 / (3600);

  if (hourDifference >= 24) {
    return false;
  }
  return true;
};

export default shareDateComparison;
