var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{class:[
    'px-4',
    'pt-4',
    'pt-lg-4',
    'pb-0',
    'pb-lg-2',
    'primaryBg',
    {
      'align-center': _vm.$vuetify.breakpoint.mdAndUp,
      'pb-2': _vm.$vuetify.breakpoint.smAndDown,
      'isOpenInPreview': _vm.isOpenInPreview,
    },
  ],attrs:{"data-qaid":`row_${_vm.material.ContentTypeId}_${_vm.material.ContentId}`,"no-gutters":""}},[_c('v-col',{attrs:{"md":"1","cols":"12"}},[_c('div',{staticClass:"overline text-uppercase",attrs:{"data-qaid":`itemDateValue_${_vm.material.ContentTypeId}_${_vm.material.ContentId}`}},[_vm._v(" "+_vm._s(_vm.material.IsRecalled ? _vm.convertDate(_vm.material.DateRecalled) : _vm.convertDate(_vm.material.Date))+" ")])]),_c('v-col',{class:[
      'materialTitle',
      'align-center',
      _vm.shareDateComparison(_vm.material.Date)
        ? 'font-weight-bold'
        : 'font-weight-medium',
    ],attrs:{"data-qaid":`itemTitle_${_vm.material.ContentTypeId}_${_vm.material.ContentId}`,"col":"12","md":"3"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-list-item-avatar',{class:[
            'custom-icon',
            { 'align-self-start': _vm.$vuetify.breakpoint.smAndDown },
          ],attrs:{"tile":"","size":_vm.$vuetify.theme.dark ? 40 : 32}},[_c('CustomIcon',{attrs:{"data-qaid":`itemIcon_${_vm.material.ContentTypeId}_${_vm.material.ContentId}`,"indicator":_vm.getIndicator(_vm.material.ContentTypeId),"is-edited":_vm.material.IsEdited ? 'edit' : '',"view":"list"}})],1)],1),_c('v-col',{attrs:{"cols":"10"}},[_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$emit('openPreviewModal', {material: _vm.material, index: _vm.index})}}},[_vm._v(" "+_vm._s(_vm.material.Title)+" ")]),_c('br'),_c('span',{staticClass:"materialContentType"},[_vm._v(" "+_vm._s(_vm.material.ContentTypeName)+" ")])])],1)],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"8"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{class:[
          'd-flex',
          'py-0',
          { 'justify-center': _vm.$vuetify.breakpoint.mdAndUp },
        ],attrs:{"cols":"12","md":"2"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.displaySettings.languageColumn.visibility),expression:"displaySettings.languageColumn.visibility"}],staticClass:"d-flex"},[(_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"overline accentText--text text-uppercase mr-2",attrs:{"data-qaid":"column_languageHeader"}},[_vm._v(" "+_vm._s(_vm.displaySettings.languageColumn.label)+" ")]):_vm._e(),_c('span',{staticClass:"lang-code mb-0 align-self-center body-2 font-weight-medium",attrs:{"data-qaid":`itemLanguage_${_vm.material.ContentTypeId}_${_vm.material.ContentId}`}},[_vm._v(" "+_vm._s(_vm.material.LanguageCode)+" ")])])]),_c('v-col',{class:['d-flex', { 'justify-center': _vm.$vuetify.breakpoint.mdAndUp }],attrs:{"md":"2"}},[_c('span',{attrs:{"data-qaid":`itemProviderValue_${_vm.material.ContentTypeId}_${_vm.material.ContentId}`}},[_vm._v(" "+_vm._s(_vm.material.Provider)+" ")])]),_c('v-col',{class:[
          'd-flex',
          'py-0',
          'print-share',
          { 'justify-center': _vm.$vuetify.breakpoint.mdAndUp },
        ],attrs:{"cols":"12","md":"2"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.displaySettings.printColumn.visibility),expression:"displaySettings.printColumn.visibility"}],staticClass:"align-self-center"},[(_vm.$vuetify.breakpoint.smAndDown)?_c('span',{staticClass:"overline accentText--text text-uppercase mr-2"},[_vm._v(" print/share ")]):_vm._e(),_c('v-badge',{class:{ shared: _vm.material.PrescribedType !== 'Printed', 'white--text light-border': _vm.$vuetify.theme.dark},attrs:{"inline":"","dot":"","bordered":"","color":_vm.material.PrescribedType === 'Printed' || _vm.material.PrescribedType === 'PrintedOnly' ? 'success' : 'inherit',"data-qaid":`itemPrescribedType_${_vm.material.ContentTypeId}_${_vm.material.ContentId}_${_vm.material.PrescribedType}`}}),_c('span',{staticClass:"mx-1 body-2 accentText--text"},[_vm._v("/")]),(!_vm.material.IsRecalled && _vm.material.PrescribedType !== 'PrintedOnly')?_c('v-badge',{attrs:{"inline":"","dot":"","bordered":"","color":"success"}}):(_vm.material.IsRecalled)?_c('v-icon',{attrs:{"color":_vm.$vuetify.theme.dark ? 'white' : 'trueBlue'}},[_vm._v("mdi-arrow-u-left-top-bold")]):_c('v-badge',{class:{'white--text light-border': _vm.$vuetify.theme.dark, 'empty': true},attrs:{"inline":"","dot":"","bordered":"","color":"inherit"}}),_c('span',{staticClass:"overline accentText--text text-uppercase font-weight-bold"},[_vm._v(" "+_vm._s(_vm.material.MethodOfAssignment ? "auto" : ""))])],1)]),_c('v-col',{class:[
          'd-flex',
          'align-center',
          { 'justify-center': _vm.$vuetify.breakpoint.mdAndUp },
        ],attrs:{"md":"2"}},[(
            _vm.$vuetify.breakpoint.smAndDown &&
            _vm.displaySettings.dischargeSummaryColumn.visibility
          )?_c('div',{staticClass:"overline accentText--text text-uppercase mr-2"},[_vm._v(" "+_vm._s(_vm.displaySettings.dischargeSummaryColumn.label)+" ")]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.displaySettings.dischargeSummaryColumn.visibility),expression:"displaySettings.dischargeSummaryColumn.visibility"}]},[(_vm.material.AddToAvs)?[(!_vm.material.IsRecalled)?_c('v-badge',{attrs:{"inline":"","dot":"","bordered":"","color":"success"}}):_c('v-icon',{attrs:{"color":_vm.$vuetify.theme.dark ? 'white' : 'trueBlue'}},[_vm._v("mdi-arrow-u-left-top-bold")])]:_vm._e()],2)]),_c('v-col',{class:[
          'd-flex',
          'align-center',
          { 'justify-center': _vm.$vuetify.breakpoint.mdAndUp },
        ],attrs:{"cols":"12","md":"2"}},[(_vm.displaySettings.readColumn.visibility)?[(_vm.$vuetify.breakpoint.smAndDown)?_c('span',{staticClass:"overline accentText--text text-uppercase mr-2"},[_vm._v(" "+_vm._s(_vm.displaySettings.readColumn.label || "carekit")+" ")]):_vm._e(),(_vm.material.IsRecalled)?_c('span',{class:[_vm.$vuetify.theme.dark ? 'white--text' : 'trueBlue--text', 'font-weight-bold']},[_c('v-icon',{attrs:{"color":_vm.$vuetify.theme.dark ? 'white' : 'trueBlue'}},[_vm._v("mdi-arrow-u-left-top-bold")]),_vm._v(" Recalled ")],1):_c('PercentViewedBadge',{attrs:{"item":_vm.material}})]:_vm._e()],2),_c('v-row',{attrs:{"no-gutters":""}},[(_vm.material.IsRecalled)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"icon":"","data-qaid":`btn-recall-expand-btn-${_vm.material.Index}`},on:{"click":function($event){_vm.rowExpanded = !_vm.rowExpanded}}},[_c('v-icon',{attrs:{"size":"32","color":"accentText"}},[_vm._v(_vm._s(_vm.rowExpanded ? "mdi-chevron-up" : "mdi-chevron-down"))])],1)],1)],1)],1):_vm._e()],1)],1)],1),(_vm.rowExpanded)?_c('v-row',{staticClass:"overline text-capitalize accentText--text",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-0",attrs:{"md":"12"}},[_c('v-col',{staticClass:"pa-0",attrs:{"md":"3","cols":"8","offset-md":"1","offset-sm":"4"}},[_c('v-col',{class:_vm.$vuetify.breakpoint.smAndDown ? 'pa-0' : 'py-0',attrs:{"offset-md":"2"}},[_vm._v(" Previous "+_vm._s(_vm.shareType)+": "+_vm._s(_vm.convertDate(_vm.material.Date))+" ")])],1)],1),_c('v-col',{staticClass:"pa-0",attrs:{"md":"12","cols":"12"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"8","md":"3","offset-md":"1","offset-sm":"4"}},[_c('v-col',{class:_vm.$vuetify.breakpoint.smAndDown ? 'pa-0': 'py-0',attrs:{"offset-md":"2"}},[_vm._v(" Percentage Viewed: "+_vm._s(_vm.material.PercentViewed ? `${_vm.material.PercentViewed}%` : "Not Viewed")+" ")])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }